const BD =[
    {
        "id":10001,
        "nombre":"Franklin Hernandez",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "PLIHSA",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10002,
        "nombre":"Victoria Jacqueline Rivera",
        "empresa": "PLIHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10003,
        "nombre":"Jean Carlos Torres",
        "empresa": "PLIHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10004,
        "nombre":"Wilfredo Lara",
        "empresa": "PLIHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10005,
        "nombre":"Eduardo Diaz",
        "empresa": "PLIHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10006,
        "nombre":"Angelo Cerrato",
        "empresa": "PLIHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10007,
        "nombre":"Mario Guevara",
        "empresa": "PLIHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10008,
        "nombre":"Jessica Suyapa Lopez Flores",
        "empresa": "PLIHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10009,
        "nombre":"Evelyn Elizabeth Osorio",
        "empresa": "PLIHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10010,
        "nombre":"Carmen Santos",
        "empresa": "PLIHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10011,
        "nombre":"Juan Jose Alvarenga",
        "empresa": "PLIHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10012,
        "nombre":"Bayron Joset Sierra Vasquez",
        "empresa": "PLIHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10013,
        "nombre":"Daniel Antonio Caballero Lorenzo",
        "empresa": "PLIHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10014,
        "nombre":"Eduardo Zuniga",
        "empresa": "Expresso Americano",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10015,
        "nombre":"Omar Ernesto Ramos",
        "empresa": "Cargil de Honduras",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10016,
        "nombre":"Oscar Daniel Villanueva",
        "empresa": "Construcciones Zuniga Suares",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10017,
        "nombre":"Eduardo Villanueva Diaz",
        "empresa": "Construcciones Zuniga Suares",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"26-01-2024",
        "fechaExpiracion":"26-01-2025"
    },
    {
        "id":10018,
        "nombre":"Santos Enrique Bueso",
        "identidad":"0501-1985-11208",
        "empresa": "CENTRHO",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"12-03-2024",
        "fechaExpiracion":"12-03-2025"
    },
    {
        "id":10019,
        "nombre":"Michael Ramirez",
        "empresa": "CENTRHO",
        "identidad":"0501-2008-14236",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"12-03-2024",
        "fechaExpiracion":"12-03-2025"
    },
    {
        "id":10020,
        "nombre":"Cristian Eduardo Mejia",
        "identidad":"0319-1991-00081",
        "empresa": "CENTRHO",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"12-03-2024",
        "fechaExpiracion":"12-03-2025"
    },
    {
        "id":10021,
        "nombre":"Carlos Dominguez",
        "identidad":"0501-1980-06088",
        "empresa": "CENTRHO",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"12-03-2024",
        "fechaExpiracion":"12-03-2025"
    },
    {
        "id":10022,
        "nombre":"Kevin Alberto Hernandez",
        "identidad":"0107-1997-00215",
        "empresa": "CENTRHO",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"12-03-2024",
        "fechaExpiracion":"12-03-2025"
    },
    {
        "id":10023,
        "nombre":"Jose Luis Ayala",
        "empresa": "SIFOR",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"12-03-2024",
        "fechaExpiracion":"12-03-2025"
    },
    {
        "id":10024,
        "nombre":"Carlos Hernandez",
        "empresa": "SIFOR",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"12-03-2024",
        "fechaExpiracion":"12-03-2025"
    },
    {
        "id":10025,
        "nombre":"Alejandro Ordoñez",
        "identidad":"0501-2004-10340",
        "empresa": "SIFOR",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"12-03-2024",
        "fechaExpiracion":"12-03-2025"
    },
    {
        "id":10026,
        "nombre":"Oscar Ordoñez",
        "identidad":"0609-1981-01549",
        "empresa": "SIFOR",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"12-03-2024",
        "fechaExpiracion":"12-03-2025"
    },
    {
        "id":10027,
        "nombre":"Angel Antonio Mejia",
        "identidad":"0401-1971-00262",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10028,
        "nombre":"Winder Alonzo Castillo",
        "identidad":"0309-1974-00016",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10029,
        "nombre":"Vertilio Ramos",
        "identidad":"1624-1975-00130",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10030,
        "nombre":"Carlos Alberto Murillo Ramos",
        "identidad":"0502-1995-01668",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10031,
        "nombre":"Nixon Yovani Rodriguez Lopez",
        "identidad":"0506-1997-00619",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10032,
        "nombre":"Hermil Josue Ordoñez Vasquez",
        "identidad":"0605-1989-00685",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10033,
        "nombre":"Hector Emilio Villeda Martinez",
        "identidad":"0413-1994-00687",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10034,
        "nombre":"Kevin Josue Gutierrez Martinez",
        "identidad":"0703-1998-02738",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10035,
        "nombre":"Alejandro Alvarado Reyes",
        "identidad":"0318-1998-00730",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10036,
        "nombre":"Alexi Geovany Romero Sanchez",
        "identidad":"0506-1974-00702",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10037,
        "nombre":"Jose Norberto Gomez Cruz",
        "identidad":"1317-1974-00218",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10038,
        "nombre":"Roberto Carlos Sanchez Rivera",
        "identidad":"0506-1989-02411",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10039,
        "nombre":"Denis Javier Molina",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10040,
        "nombre":"Jose Javier Gamez",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10041,
        "nombre":"Brayan Joel Rosales Santos",
        "identidad":"1804-1991-02430",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"15-03-2024",
        "fechaExpiracion":"15-03-2025"
    },
    {
        "id":10042,
        "nombre":"Cesar Barahona",
        "empresa": "Ingenieria Moderna",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"18-03-2024",
        "fechaExpiracion":"18-03-2025"
    },
    {
        "id":10043,
        "nombre":"Franklyn Fernandez",
        "empresa": "Ingenieria Moderna",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"18-03-2024",
        "fechaExpiracion":"18-03-2025"
    },
    {
        "id":10044,
        "nombre":"Javier Aguilar",
        "empresa": "Ingenieria Moderna",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"18-03-2024",
        "fechaExpiracion":"18-03-2025"
    },
    {
        "id":10045,
        "nombre":"Jhonathan David Tejada ",
        "empresa": "Ingenieria Moderna",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"18-03-2024",
        "fechaExpiracion":"18-03-2025"
    },
    {
        "id":10046,
        "nombre":"Jose Lopez",
        "empresa": "Ingenieria Moderna",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"18-03-2024",
        "fechaExpiracion":"18-03-2025"
    },
    {
        "id":10047,
        "nombre":"Juan Diego Torres",
        "empresa": "Ingenieria Moderna",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"18-03-2024",
        "fechaExpiracion":"18-03-2025"
    },
    {
        "id":10048,
        "nombre":"Kevin Matute",
        "empresa": "Ingenieria Moderna",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"18-03-2024",
        "fechaExpiracion":"18-03-2025"
    },
    {
        "id":10049,
        "nombre":"Luis David Argueta",
        "empresa": "Ingenieria Moderna",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"18-03-2024",
        "fechaExpiracion":"18-03-2025"
    },
    {
        "id":10050,
        "nombre":"Luis Edgardo Argueta",
        "empresa": "Ingenieria Moderna",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"18-03-2024",
        "fechaExpiracion":"18-03-2025"
    },
    {
        "id":10051,
        "nombre":"Maynor Alvarado",
        "empresa": "Ingenieria Moderna",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"18-03-2024",
        "fechaExpiracion":"18-03-2025"
    },
    {
        "id":10052,
        "nombre":"Reynaldo Reyes",
        "empresa": "Ingenieria Moderna",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"18-03-2024",
        "fechaExpiracion":"18-03-2025"
    },
    {
        "id":10053,
        "nombre":"Omar Ernesto Ramos Lopez",
        "empresa": "Cargil de Honduras",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"18-03-2024",
        "fechaExpiracion":"18-03-2025"
    },
    {
        "id":10054,
        "nombre":"Brayan Joel Rosales Santos",
        "identidad":"1804-1991-02430",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel S.A",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },
    {
        "id":10055,
        "nombre":"Alba Rosa Diaz Maradiaga",
        "identidad":"1627-1988-00228",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel S.A",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },
    {
        "id":10056,
        "nombre":"Maria Isabel Alba Paz",
        "identidad":"0501-1993-10134",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel S.A",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },
    {
        "id":10057,
        "nombre":"Armando Antonio Sanchez",
        "identidad":"0107-1988-01860",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel S.A",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },
    {
        "id":10058,
        "nombre":"Roger Emilio Claros",
        "identidad":"1007-1989-00756",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel S.A",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },
    {
        "id":10059,
        "nombre":"Laura Eunice Gonzales",
        "identidad":"0501-1996-04248",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel S.A",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },
    {
        "id":10060,
        "nombre":"Mayli Daniela Landaverde",
        "identidad":"0210-1998-00540",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel S.A",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },
    {
        "id":10061,
        "nombre":"Sergio David Sanchez",
        "identidad":"0801-1996-04501",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel S.A",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },
    {
        "id":10062,
        "nombre":"Roberto Carlos Sanchez",
        "identidad":"0506-1989-02411",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel S.A",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },
    {
        "id":10063,
        "nombre":"Jose Lenin Nuñes Bardales",
        "identidad":"1605-1999-00034",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel S.A",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },
    {
        "id":10064,
        "nombre":"Jesus Gilberto Osejo",
        "identidad":"0506-1984-01854",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel S.A",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },    
    {
        "id":10065,
        "nombre":"Diego Francisco Luna",
        "identidad":"0801-1989-02720",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Granel S.A",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },
    {
        "id":10066,
        "nombre":"Javier Enrique Medina",
        "identidad":"0501-1993-11362",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Ingenieria Modera",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },
    {
        "id":10067,
        "nombre":"Cesar Geovani Barahona",
        "identidad":"0508-1985-00418",
        "tipoCertificado": "Trabajos en Altura",
        "empresa": "Ingenieria Modera",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-04-2024",
        "fechaExpiracion":"16-04-2025"
    },
    {
        "id":10068,
        "nombre":"Osman Ricardo Alvarado",
        "identidad":"1613-1990-00471",
        "empresa": "Granel",
        "tipoCertificado": "Trabajo en Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10069,
        "nombre":"Jose Amadeo Vasquez",
        "identidad":"0204-1997-00183",
        "empresa": "Granel",
        "tipoCertificado": "Trabajo en Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10070,
        "nombre":"Oscar David Martinez Lopez",
        "identidad":"0501-1995-05646",
        "empresa": "Granel",
        "tipoCertificado": "Trabajo en Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10071,
        "nombre":"Rigo Alberto Acevedo Guzman",
        "identidad":"0506-2002-02417",
        "empresa": "Granel",
        "tipoCertificado": "Trabajo en Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10072,
        "nombre":"Jefry Ramon Garay",
        "identidad":"0502-1996-00212",
        "empresa": "Granel",
        "tipoCertificado": "Trabajo en Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10073,
        "nombre":"Gerson Bonilla",
        "identidad":"0506-2004-02215",
        "empresa": "Granel",
        "tipoCertificado": "Trabajo en Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10074,
        "nombre":"Jose Luis Guardado Guevara",
        "identidad":"0502-1994-00357",
        "empresa": "Granel",
        "tipoCertificado": "Trabajo en Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10075,
        "nombre":"Dennis Javier Avila Perdomo",
        "identidad":"1610-2001-00066",
        "empresa": "Granel",
        "tipoCertificado": "Trabajo en Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10076,
        "nombre":"Edgar Daniel Lopez Lara",
        "identidad":"0503-2004-00326",
        "empresa": "Granel",
        "tipoCertificado": "Trabajo en Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10077,
        "nombre":"Arnol Maldonado Ramirez",
        "identidad":"0501-2002-06368",
        "empresa": "Granel",
        "tipoCertificado": "Trabajo en Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10078,
        "nombre":"Marlon Eduardo Bueso Flores",
        "identidad":"0313-1997-00008",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10079,
        "nombre":"Lelis Cruz Barahona",
        "identidad":"1612-1997-00256",
        "empresa": "GEA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10080,
        "nombre":"Julio Cesar Menjivar",
        "identidad":"0506-1988-00173",
        "empresa": "GEA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10081,
        "nombre":"Hans Eskelsen",
        "identidad":"0501-1976-02808",
        "empresa": "GEA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10082,
        "nombre":"Rigoberto Castro",
        "identidad":"0502-2005-00061",
        "empresa": "GEA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10083,
        "nombre":"Gustavo Eduardo Varela",
        "identidad":"0501-1992-01397",
        "empresa": "GEA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10084,
        "nombre":"Danilo Alva",
        "identidad":"1616-1971-00223",
        "empresa": "GEA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10085,
        "nombre":"Abimahel Gomez Diaz",
        "identidad":"0502-1985-01296",
        "empresa": "GEA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10086,
        "nombre":"Marvin Gomez",
        "identidad":"0502-1984-00435",
        "empresa": "GEA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10087,
        "nombre":"Miguel Angel Guerra",
        "identidad":"0502-1983-01476",
        "empresa": "GEA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10088,
        "nombre":"Daniel Enrique Duran",
        "identidad":"1807-1991-00191",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10089,
        "nombre":"Jose Ignacio Anduga Pliego",
        "empresa": "GEA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10090,
        "nombre":"Tania Hernandez",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-04-2024",
        "fechaExpiracion":"22-04-2025"
    },
    {
        "id":10091,
        "nombre":"Merlin Rosa",
        "empresa": "Geotermica Platanares",
        "identidad":"0412-1995-00316",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"29-04-2024",
        "fechaExpiracion":"29-04-2025"
    },
    {
        "id":10092,
        "nombre":"Edwin Perez",
        "identidad":"0406-1989-00096",
        "empresa": "Geotermica Platanares",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"29-04-2024",
        "fechaExpiracion":"29-04-2025"
    },
    {
        "id":10093,
        "nombre":"Brayan Giron Lemus",
        "identidad":"0401-1991-01302",
        "empresa": "Geotermica Platanares",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"29-04-2024",
        "fechaExpiracion":"29-04-2025"
    },
    {
        "id":10094,
        "nombre":"Fredy Leonel Murcia",
        "identidad":"1401-1991-00134",
        "empresa": "Geotermica Platanares",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"29-04-2024",
        "fechaExpiracion":"29-04-2025"
    },
    {
        "id":10095,
        "nombre":"Adonay Trochez Duarte",
        "identidad":"1806-1998-01092",
        "empresa": "Proteico",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"06-05-2024",
    },
    {
        "id":10096,
        "nombre":"Arnol Estrada",
        "identidad":"0609-1992-00236",
        "empresa": "Proteico",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"06-05-2024",
    },
    {
        "id":10097,
        "nombre":"Jorge Rodriguez",
        "identidad":"0502-1996-01688",
        "empresa": "Proteico",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"06-05-2024",
    },
    {
        "id":10098,
        "nombre":"Nicolas Quiñonez",
        "identidad":"0501-1990-01348",
        "empresa": "Proteico",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"06-05-2024",
    },
    {
        "id":10099,
        "nombre":"Oscar Andres Sevilla",
        "identidad":"0501-1996-08146",
        "empresa": "Proteico",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"06-05-2024",
    },
    {
        "id":10100,
        "nombre":"Marco Tulio Hernandez",
        "identidad":"1801-2006-00686",
        "empresa": "Proteico",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"06-05-2024",
    },
    {
        "id":10101,
        "nombre":"Allan Ricardo Lopez",
        "identidad":"0801-1989-16814",
        "empresa": "Proteico",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"06-05-2024",
    },
    {
        "id":10102,
        "nombre":"Emanuel Edgardo Lopez",
        "identidad":"0501-1998-08710",
        "empresa": "Proteico",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"06-05-2024",
    },
    {
        "id":10103,
        "nombre":"Erick Sanchez",
        "identidad":"0501-1984-09977",
        "empresa": "Titulo personal",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"04-05-2024",
    },
    {
        "id":10104,
        "nombre":"Wilber Bernardez",
        "identidad":"0203-2005-00659",
        "empresa": "Titulo Peronal",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"04-05-2024",
    },
    {
        "id":10105,
        "nombre":"Victor mejia",
        "identidad":"0501-1985-06371",
        "empresa": "Titulo Personal",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"04-05-2024",
    },
    {
        "id":10106,
        "nombre":"Jairo Alexis Vijil",
        "identidad":"0501-1988-01591",
        "empresa": "Ingenieria y Servicios Industriales",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"24-05-2024",
    },
    {
        "id":10107,
        "nombre":"Alex Esquivel",
        "identidad":"0409-1993-00702",
        "empresa": "Ingenieria y Servicios Industriales",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"24-05-2024",
    },
    {
        "id":10108,
        "nombre":"Miguel Angel Santiago Martinez",
        "identidad":"1317-1981-00050",
        "empresa": "Ingenieria y Servicios Industriales",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"24-05-2024",
    },
    {
        "id":10109,
        "nombre":"Arnol Morales",
        "identidad":"0502-1993-06042",
        "empresa": "Ingenieria y Servicios Industriales",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"24-05-2024",
    },
    {
        "id":10110,
        "nombre":"Daniel Rivera",
        "identidad":"1807-1982-00653",
        "empresa": "Ingenieria y Servicios Industriales",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"24-05-2024",
    },
    {
        "id":10111,
        "nombre":"Edwin Reyes Vega",
        "identidad":"0511-1985-00262",
        "empresa": "Ingenieria y Servicios Industriales",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"24-05-2024",
    },
    {
        "id":10112,
        "nombre":"Victor Yonatan Castro",
        "identidad":"1623-1994-00066",
        "empresa": "Ingenieria y Servicios Industriales",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"24-05-2024",
    },
    {
        "id":10113,
        "nombre":"Wilmar Antonio Contreras",
        "identidad":"0410-1978-00938",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"24-05-2024",
    },
    {
        "id":10114,
        "nombre":"Miguel Angel Barahona Rubi",
        "identidad":"1623-1978-00256",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-05-2024",
    },
    {
        "id":10115,
        "nombre":"Brayan Josue Mejia Cruz",
        "identidad":"0508-1997-00490",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-05-2024",
    },
    {
        "id":10116,
        "nombre":"Michael Smile Barahona Argueta",
        "identidad":"0508-2000-00242",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-05-2024",
    },
    {
        "id":10117,
        "nombre":"Jose Rosendo Barahona Lezama",
        "identidad":"0508-2000-00021",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-05-2024",
    },
    {
        "id":10118,
        "nombre":"Carlos Ernesto Polanco Linares",
        "identidad":"1414-1998-00138",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-05-2024",
    },
    {
        "id":10119,
        "nombre":"Fredy Geovanny Ramirez Melgar",
        "identidad":"0107-1991- 02387",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-05-2024",
    },
    {
        "id":10120,
        "nombre":"Darwin Josue Soriano Meza",
        "identidad":"0508-2002-00478",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-05-2024",
    },
    {
        "id":10121,
        "nombre":"Victor Manuel Miranda Gamez",
        "identidad":"0501-1990-01601",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-05-2024",
    },
    {
        "id":10122,
        "nombre":"Gleen Yordy Enamorado Lara",
        "identidad":"0511-1998-00879",
        "empresa": "IPROMEC",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-06-2024",
    },
    {
        "id":10123,
        "nombre":"Kevin Adonay Ponce Pineda",
        "identidad":"0511-1993-00844",
        "empresa": "IPROMEC",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-06-2024",
    },
    {
        "id":10124,
        "nombre":"Anwar Farid Salgado Borjas",
        "identidad":"0107-1998-00839",
        "empresa": "TECNOMAC",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-06-2024",
    },
    {
        "id":10125,
        "nombre":"Oscar Josue Diaz Berrios",
        "identidad":"1804-1997-02465",
        "empresa": "TECNOMAC",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-06-2024",
    },
    {
        "id":10126,
        "nombre":"Denis Eduardo Cruz Aquino",
        "identidad":"0505-1996-00466",
        "empresa": "IPROMEC",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-06-2024",
    },
    {
        "id":10127,
        "nombre":"Jorge Isaac Rivera Reyes",
        "identidad":"1804-1998-01008",
        "empresa": "TECNOMAC",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-06-2024",
    },
    {
        "id":10128,
        "nombre":"Ronal Jafeth Martinez Posadas",
        "identidad":"0501-2003-04992",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-06-2024",
    },
    {
        "id":10129,
        "nombre":"Michael Alexander Lopez",
        "identidad":"0501-1994-03134",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-06-2024",
    },
    {
        "id":10130,
        "nombre":"Cristofer Roberto Carcamo",
        "identidad":"0501-1996-07503",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-06-2024",
    },
    {
        "id":10131,
        "nombre":"Edwin Daniel Gonzales",
        "identidad":"0501-2005-05159",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-06-2024",
    },
    {
        "id":10132,
        "nombre":"Oscar Lopez Ruiz",
        "identidad":"0106-1965-00112",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"11-06-2024",
    },
    {
        "id":10133,
        "nombre":"Samuel David Enriquez",
        "identidad":"0502-1998-03916",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-06-2024",
    },
    {
        "id":10134,
        "nombre":"Yunior Argenis Aguilar",
        "identidad":"0610-2002-00294",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-06-2024",
    },
    {
        "id":10135,
        "nombre":"Noel Eduardo Torres",
        "identidad":"0610-2002-00516",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-06-2024",
    },
    {
        "id":10136,
        "nombre":"Carlos Augusto Garcia",
        "identidad":"0510-1976-00888",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-06-2024",
    },
    {
        "id":10137,
        "nombre":"Melvin Gerardo Garcia",
        "identidad":"0502-1993-02343",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-06-2024",
    },
    {
        "id":10138,
        "nombre":"Marvin Bobadilla",
        "identidad":"0503-1980-00905",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-06-2024",
    },
    {
        "id":10139,
        "nombre":"Bayron Josue Merlo Peña",
        "identidad":"0501-1992-04705",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-06-2024",
    },
    {
        "id":10140,
        "nombre":"Michael David Bobadilla",
        "identidad":"0501-2005-01415",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-06-2024",
    },
    {
        "id":10141,
        "nombre":"Ladan Asael Espino Sanchez",
        "identidad":"0602-1994-00186",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-06-2024",
    },
    {
        "id":10142,
        "nombre":"Laudo Alexander Lopez",
        "identidad":"0501-2004-06147",
        "empresa": "Sinetyc",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-06-2024",
    },
    {
        "id":10143,
        "nombre":"Leticia Canales Carcamo",
        "identidad":"0501-1971-02789",
        "empresa": "Inversiones CYZ Ingenieria",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-07-2024",
    },
    {
        "id":10144,
        "nombre":"Kevin Martinez",
        "identidad":"0506-1999-02012",
        "empresa": "Inversiones CYZ Ingenieria",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-07-2024",
    },
    {
        "id":10145,
        "nombre":"Elder Misael Martinez",
        "identidad":"0715-1979-00613",
        "empresa": "Inversiones CYZ Ingenieria",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-07-2024",
    },
    {
        "id":10146,
        "nombre":"Oscar Roberto Serrano",
        "identidad":"0502-1986-01650",
        "empresa": "Inversiones CYZ Ingenieria",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-07-2024",
    },
    {
        "id":10147,
        "nombre":"Roberto Orellana",
        "identidad":"0502-1979-01040",
        "empresa": "Inversiones CYZ Ingenieria",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-07-2024",
    },
    {
        "id":10148,
        "nombre":"Darlyn Bautista",
        "identidad":"0208-1989-01711",
        "empresa": "Inversiones CYZ Ingenieria",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-07-2024",
    },
    {
        "id":10149,
        "nombre":"Yuri Herrera",
        "identidad":"1626-1991-00124",
        "empresa": "Inversiones CYZ Ingenieria",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-07-2024",
    },
    {
        "id":10150,
        "nombre":"Jorge Lainez",
        "identidad":"0107-1996-01762",
        "empresa": "Inversiones CYZ Ingenieria",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"13-07-2024",
    },
    {
        "id":10151,
        "nombre":"Jorge Luis Bustillo Lopez",
        "identidad":"0501-1984-06258",
        "empresa": "Prodesula",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-07-2024",
    },
    {
        "id":10152,
        "nombre":"Cesar Joel Martinez Contreras",
        "identidad":"0501-1997-05037",
        "empresa": "Prodesula",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-07-2024",
    },
    {
        "id":10153,
        "nombre":"Elmer Josue Bardales Rios",
        "identidad":"0623-2003-00087",
        "empresa": "Prodesula",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-07-2024",
    },
    {
        "id":10154,
        "nombre":"Alejandro Antonio Liomez lopez",
        "identidad":"1804-1989-05945",
        "empresa": "Prodesula",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-07-2024",
    },
    {
        "id":10155,
        "nombre":"Selvin Antonio Meza",
        "identidad":"0201-1996-01447",
        "empresa": "Prodesula",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-07-2024",
    },
    {
        "id":10156,
        "nombre":"Fernando Jose Paguada Flores",
        "identidad":"1503-1998-01506",
        "empresa": "Prodesula",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-07-2024",
    },
    {
        "id":10157,
        "nombre":"Favio Ruiz Lopez",
        "identidad":"0501-2008-08403",
        "empresa": "Prodesula",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-07-2024",
    },
    {
        "id":10158,
        "nombre":"David Jonathan Castio Baide",
        "identidad":"0501-1983-01864",
        "empresa": "Prodesula",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-07-2024",
    },
    {
        "id":10159,
        "nombre":"Jose Noeli Nuñez Martinez",
        "identidad":"1617-1999-00388",
        "empresa": "Prodesula",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-07-2024",
    },
    {
        "id":10160,
        "nombre":"Celso Noe Andino Carranza",
        "identidad":"0505-1994-00314",
        "empresa": "Prodesula",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"16-07-2024",
    },
    {
        "id":10161,
        "nombre":"Edwin Yovany Calderon",
        "identidad":"0401-1983-00036",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"04-05-2024",
    },
    {
        "id":10162,
        "nombre":"Ramon Miguel Moreno Paz",
        "identidad":"0501-1984-13776",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"04-05-2024",
    },
    {
        "id":10163,
        "nombre":"Bairon Enrique Palacios",
        "identidad":"0510-1994-00497",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"04-05-2024",
    },
    {
        "id":10164,
        "nombre":"Elmer Alexander Melendez",
        "identidad":"1618-1998-01099",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"04-05-2024"
    },
    {
        "id":10165,
        "nombre":"Hector David Barahona",
        "identidad":"1607-1991-00202",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"04-05-2024",
    },
    {
        "id":10166,
        "nombre":"Karina Gabriela Martinez",
        "identidad":"0801-1994-01856",
        "empresa": "Enercom",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"04-05-2024",
    },
    {
        "id":10167,
        "nombre":"Lennin Francisco Saravia",
        "identidad":"1804-1990-04351",
        "empresa": "HONDUPALMA",
        "tipoCertificado": "Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"08-08-2024",
    },
    {
        "id":10168,
        "nombre":"Edwin Yadir Ruiz",
        "identidad":"1804-1988-02285",
        "empresa": "HONDUPALMA",
        "tipoCertificado": "Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"08-08-2024",
    },
    {
        "id":10169,
        "nombre":"Yorleniz Yolibet Garcia",
        "identidad":"1804-1989-06834",
        "empresa": "HONDUPALMA",
        "tipoCertificado": "Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"08-08-2024",
    },
    {
        "id":10170,
        "nombre":"Jerson Wilfredo Garcia",
        "identidad":"1804-2000-04264",
        "empresa": "HONDUPALMA",
        "tipoCertificado": "Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"08-08-2024",
    },
    {
        "id":10171,
        "nombre":"Ceily Araya Acevedo",
        "identidad":"1803-1996-00574",
        "empresa": "HONDUPALMA",
        "tipoCertificado": "Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"08-08-2024",
    },
    {
        "id":10172,
        "nombre":"Darwin Ruiz",
        "identidad":"1804-1990-00097",
        "empresa": "HONDUPALMA",
        "tipoCertificado": "Espacios Confinados",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"08-08-2024",
    },
    {
        "id":10173,
        "nombre":"Walther Eduardo Estrada Romero",
        "identidad":"E0880522",
        "empresa": "INDUSTRIAL OBRAS MECANICAS S. DE R.L.",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-08-2024",
    },
    {
        "id":10174,
        "nombre":"Fernando Jose Orellana Soza",
        "identidad":"E0880524",
        "empresa": "INDUSTRIAL OBRAS MECANICAS S. DE R.L.",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-08-2024",
    },
    {
        "id":10175,
        "nombre":"Kevin Alejandro Estrada Romero",
        "identidad":"E0880523",
        "empresa": "INDUSTRIAL OBRAS MECANICAS S. DE R.L.",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-08-2024",
    },
    {
        "id":10176,
        "nombre":"Marcos Alexander Quintano Orellana",
        "identidad":"E0638772",
        "empresa": "INDUSTRIAL OBRAS MECANICAS S. DE R.L.",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-08-2024",
    },
    {
        "id":10177,
        "nombre":"Jairo Hugo Castañeda Leiva",
        "identidad":"E0647307",
        "empresa": "INDUSTRIAL OBRAS MECANICAS S. DE R.L.",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-08-2024",
    },
    {
        "id":10178,
        "nombre":"Wilmer Andres Gomes Fuentes",
        "identidad":"G587602",
        "empresa": "INDUSTRIAL OBRAS MECANICAS S. DE R.L.",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-08-2024",
    },
    {
        "id":10179,
        "nombre":"Kerin Enoc Estrada Lopez",
        "identidad":"E1043378",
        "empresa": "INDUSTRIAL OBRAS MECANICAS S. DE R.L.",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-08-2024",
    },
    {
        "id":10180,
        "nombre":"Olvin Moran Valenzuela",
        "identidad":"E0878572",
        "empresa": "INDUSTRIAL OBRAS MECANICAS S. DE R.L.",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"22-08-2024",
    },
    {
        "id":10181,
        "nombre":"Gerardo Martinez",
        "identidad":"0101-2002-01924",
        "empresa": "INVERSIONES CYZ INGENIERIA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"30-08-2024",
    },
    {
        "id":10182,
        "nombre":"Jose Torres",
        "identidad":"0103-1989-00639",
        "empresa": "INVERSIONES CYZ INGENIERIA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"30-08-2024",
    },
    {
        "id":10183,
        "nombre":"Ludin Cruz",
        "identidad":"0201-2005-00615",
        "empresa": "INVERSIONES CYZ INGENIERIA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"30-08-2024",
    },
    {
        "id":10184,
        "nombre":"Carlos Torres",
        "identidad":"0107-1992-02204",
        "empresa": "INVERSIONES CYZ INGENIERIA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"30-08-2024",
    },
    {
        "id":10185,
        "nombre":"Eldin Canales",
        "identidad":"0201-2002-00244",
        "empresa": "INVERSIONES CYZ INGENIERIA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"30-08-2024",
    },
    {
        "id":10186,
        "nombre":"Angel Molina",
        "identidad":"0201-2000-00493",
        "empresa": "INVERSIONES CYZ INGENIERIA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"30-08-2024",
    },
    {
        "id":10187,
        "nombre":"Luis Rodriguez",
        "identidad":"0108-1997-00090",
        "empresa": "INVERSIONES CYZ INGENIERIA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"30-08-2024",
    },
    {
        "id":10188,
        "nombre":"Santos Martinez",
        "identidad":"0511-1979-00139",
        "empresa": "INVERSIONES CYZ INGENIERIA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"30-08-2024",
    },
    {
        "id":10189,
        "nombre":"Edwin Campos",
        "identidad":"0512-1996-00512",
        "empresa": "CECHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"30-08-2024",
    },
    {
        "id":10190,
        "nombre":"Litzy Flores",
        "identidad":"1612-2000-00005",
        "empresa": "CECHSA",
        "tipoCertificado": "Trabajos en Altura",
        "instructor":"Mario Ernesto Chinchilla Quintanilla",
        "fechaCertificacion":"30-08-2024",
    },
]

module.exports ={
    BD
}